import { connect } from "react-redux";
import Footer from "components/navigation/Footer"
import Layout from "hocs/layouts/Layout"
import { useEffect } from "react"
import moment from 'moment'
import { Link } from "react-router-dom"
import NavigationBar from "components/navigation/NavigationBar";
import { useState } from "react";
import Services from "components/home/Services";
import Benefits from "components/home/Benefits";
function Blog({




}) {



    return (
        <Layout>
            <NavigationBar />
            <Services/>

            <Footer />
        </Layout >
    )
}
const mapStateToProps = state => ({



})

export default connect(mapStateToProps, {


})(Blog)