import { connect } from "react-redux"
import { motion } from "framer-motion"
import { ChatDotsFill, Alexa, GearWideConnected, Robot , Cpu } from "react-bootstrap-icons"
import ajustes from "assets/img/ajustes.png";
import prueba from "assets/img/prueba.png";
import robotico from "assets/img/robotico.png";
function FeatureSection() {


  return (
    <>

      <section class="feature_section layout_padding2 layout_margin">
        <div class="container my-0">
          <div class="heading_container">
            <motion.div initial={{ y: -100, opacity: 0 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.1, duration: 1 }} viewport={{ once: true }}  >

              <h2 className="text-center mb-5">
                Servicios
              </h2>
              <p className="text-center" >Desarrollamos soluciones a la medida de tus necesidades.</p>
            </motion.div>
          </div>
        </div>
        <div class="container my-4">
          <div class="row">
            <div class="col-md-4">
              <motion.div initial={{ y: -100, opacity: 0 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.1, duration: 1 }} viewport={{ once: true }}  >
                <div class="box my-3">
                  <div class="head-box">
                    <div class="img-box">
     
                    <img className="img-fluid" src={robotico}></img>
                    </div>
                    <h6>
                      Chatbots
                    </h6>
                  </div>
                  <div class="detail-box">
                    <p>
                      Chatbots que pueden interactuar a través de plataformas como Whatsapp, Redes sociales, SMS y mail.
                    </p>
                  </div>
                </div>
              </motion.div>

            </div>
            <div class="col-md-4">
              <motion.div initial={{ y: -100, opacity: 0 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.1, duration: 1 }} viewport={{ once: true }}  >
                <div class="box my-3">
                  <div class="head-box">
                    <div class="img-box">

                      <img className="img-fluid" src={ajustes}></img>
                    
                    </div>
                    <h6>
                      RPA
                    </h6>
                  </div>
                  <div class="detail-box">
                    <p>
                    Implementa RPA para automatizar tareas repetitivas de alto volumen como la entrada de datos y la generación de reportes, eliminando errores manuales y optimizando procesos. Adaptable y escalable.
                    </p>
                  </div>
                </div>
              </motion.div>

            </div>
            <div class="col-md-4">
              <motion.div initial={{ y: -100, opacity: 0 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.1, duration: 1 }} viewport={{ once: true }}  >
                <div class="box my-3">
                  <div class="head-box">
                    <div class="img-box">
                    <img className="img-fluid" src={prueba}></img>

                    </div>
                    <h6>
                      Desarrollo de software
                    </h6>
                  </div>
                  <div class="detail-box">
                    <p>
                    Soluciones enfocadas en el desarrollo de software personalizado para empresas que buscan optimizar sus operaciones internas a través de la automatización.
                    </p>
                  </div>
                </div>
              </motion.div>

            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <a href="/servicios">
            Leer más
          </a>
        </div>

      </section>




    </>
  )
}
const mapStateToProps = state => ({

})
export default connect(mapStateToProps, {

})(FeatureSection)