import { connect } from "react-redux";
import Footer from "components/navigation/Footer"
import NavigationBar from "components/navigation/NavigationBar";
import Layout from "hocs/layouts/Layout"
import { useEffect, useState } from "react"
import { get_new } from "redux/actions/news/news"
import { useParams } from "react-router-dom";
import DOMPurify from 'dompurify'
import { Helmet } from "react-helmet-async";
import { ArrowLeftCircle } from "react-bootstrap-icons";
import delay from 'delay';
function NoticiasDetail({ get_new, post }) {
    const [loading, setLoading] = useState(true);
    const params = useParams()
    const slug = params.slug
    useEffect(() => {
        const fetchData = async () => {
            await delay(1000);  // Esperar 1 segundo antes de cargar los datos
            await get_new(slug);
            setLoading(false);
        };


        window.scrollTo(0, 0);
        fetchData();
    }, [slug, get_new]);

    if (loading) {
        return <div>Cargando...</div>;  // Mostrar un mensaje de carga
    }
    return (
        <Layout>
            <NavigationBar />
            {post ? (<div className="container my-5">

                <h2 className="h2-title text-center"> {post.title}</h2>
                <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.content) }} />




            </div>) :("cargando.....") }
            <div className="container">

                <a href="/blog" style={{ textDecoration: "none" }}>
                    <button>

                        <svg width="34" height="34" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">

                            <ArrowLeftCircle color="white" size={"100%"} />
                        </svg>
                        <span className="mx-2">Ver más Noticias</span>
                    </button>
                </a>
            </div>




            <Footer />
        </Layout>
    )
}
const mapStateToProps = state => ({

    post: state.news.post

})

export default connect(mapStateToProps, {
    get_new


})(NoticiasDetail)