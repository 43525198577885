
import { connect } from "react-redux"
import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import { Card } from "react-bootstrap";



function CardBlog({ title, description, date, thumbnail }) {


    return (
        <>

            <article class="blog-post">
                <img src={thumbnail} alt="" className="img-fluid" />

                <div class="content">
                    <small>{date}</small>
                    <h5>{title}</h5>
                    <p>{description}</p>
                </div>
            </article>




        </>
    )
}
const mapStateToProps = state => ({

})
export default connect(mapStateToProps, {


})(CardBlog)  