
import { connect } from "react-redux"

import React from "react";

import Card from 'react-bootstrap/Card';

function FeaturedSection({ title, description, date, thumbnail }) {


    return (
        <>
            <Card className="bg-dark text-white">
                <Card.Img src={thumbnail} alt="Card image" />
                <Card.ImgOverlay className="card-img-overlay">
                    <Card.Title>{title}</Card.Title>
                    <Card.Text>
                        {description}
                    </Card.Text>
                    <Card.Text>{date}</Card.Text>
                </Card.ImgOverlay>
            </Card>




        </>
    )
}
const mapStateToProps = state => ({

})
export default connect(mapStateToProps, {


})(FeaturedSection)  