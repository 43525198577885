import { connect } from "react-redux";
import { get_news, get_filtered, get_blog_list_page } from "redux/actions/news/news"
import Footer from "components/navigation/Footer"
import Layout from "hocs/layouts/Layout"
import { useEffect } from "react"
import moment from 'moment'
import { Link } from "react-router-dom"
import NavigationBar from "components/navigation/NavigationBar";
import { useState } from "react";
import Benefits from "components/home/Benefits";

function Blog({




}) {

    const [formData, setFormData] = useState({
        search: '',

    });

    const { search, } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });




    const onSubmit = e => {

        e.preventDefault();
        let formData = new FormData()
        formData.append('search', search)

        let busqueda = formData.get('search')

        get_filtered(busqueda);


    }


    useEffect(() => {
        get_news()


    }, [])

    return (
        <Layout>
            <NavigationBar />
            <Benefits/>

            <Footer />
        </Layout >
    )
}
const mapStateToProps = state => ({



})

export default connect(mapStateToProps, {


})(Blog)