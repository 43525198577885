import { connect } from "react-redux"
import React from 'react';
import { Modal } from 'react-bootstrap'; // Si estás usando React Bootstrap
import { useState } from "react";
import axios from "axios";
import comprobado from "assets/img/comprobado.png";
import politica from "assets/docs/politica.pdf";
function Contacto({ show, handleClose }) {
    const [loading, setLoading] = useState(false);
    const [agreed, setAgreed] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false); // Nuevo estado para controlar si se ha enviado el formulario

    const [formData, setFormData] = useState({
        name: '',
        lastName: '',
        country: 'Colombia',
        phone: '',
        email: '',
        company: '',
        employees: '1-4',
    });

    const { name,
        lastName,
        country,
        phone,
        email,
        company,
        employees } = formData;
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });
    const onSubmit = e => {
        // Cambia el estado de carga a true al comenzar el envío del formulario


        e.preventDefault();

        if (agreed) {
            setLoading(true);

            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            let formData = new FormData()
            formData.append('name', name)
            formData.append('lastName', lastName)
            formData.append('email', email)
            formData.append('phone', phone)
            formData.append('country', country)
            formData.append('company', company)
            formData.append('employees', employees)




            const fetchData = async () => {
                try {
                    const res = await axios.post(
                        `${process.env.REACT_APP_API_URL}/api/contacts/`,
                        formData,
                        config
                    );
                    // Cambia el estado a 'formSubmitted' cuando la solicitud sea exitosa
                    setFormSubmitted(true);
                } catch (error) {
                    if (error.response && error.response.status === 404) {
                        alert(
                            'Error al enviar datos, es posible que ya te encuentres registrado'
                        );
                    } else {
                        // Lógica para otros errores
                    }
                } finally {
                    setLoading(false); // Asegura que setLoading se ejecute incluso si hay un error
                }
            };
            fetchData()


        } else {
            alert("Debes aceptar la politica de tratamiento de datos")
        }
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} centered size="lg" >
                <div className="modal-content">
                    <div className="modal-body p-0">
                        {formSubmitted ? (
                            <div className="container my-4">
                                <div className="d-flex justify-content-center">
                                    <img src={comprobado} className="img-fluid" alt="Formulario enviado" />
                                </div>
                                <h1 className="text-center mt-4">Formulario enviado con éxito.</h1>
                                <p className="my-2 text-center">Pronto nos pondremos en contacto contigo.</p>
                            </div>

                        ) : (
                            <div className="container-fluid">
                                <div className="row gy-4">
                                    <div className="col-lg-12">
                                        <form onSubmit={e => onSubmit(e)} method="POST" className="p-lg-5 col-12 row g-3">
                                            <div>
                                                <h1>¡Contáctanos!</h1>
                                                <p>No dudes en contactarnos y nos comunicaremos contigo lo antes posible.</p>
                                            </div>
                                            <div className="col-lg-6">
                                                <label htmlFor="userName" className="form-label">Nombre* </label>
                                                <input required value={name} name="name" onChange={e => onChange(e)} type="text" className="form-control" placeholder="Digita tu Nombre" id="name" />
                                            </div>
                                            <div className="col-lg-6">
                                                <label htmlFor="lastName" className="form-label">Apellido*</label>
                                                <input required value={lastName} name="lastName" onChange={e => onChange(e)} type="text" className="form-control" placeholder="Digita tu Apellido" id="lastName" />
                                            </div>
                                            <div className="col-lg-3">
                                                <label htmlFor="country" className="form-label">País*</label>
                                                <select required value={country} name="country" onChange={e => onChange(e)} id="country" className="form-control"   >
                                                    <option value="Colombia" selected>Colombia</option>
                                                    <option value="Argentina">Argentina</option>
                                                    <option value="Bolivia">Bolivia</option>
                                                    <option value="Brasil">Brasil</option>
                                                    <option value="Chile">Chile</option>
                                                    <option value="Costa Rica">Costa Rica</option>
                                                    <option value="Cuba">Cuba</option>
                                                    <option value="Ecuador">Ecuador</option>
                                                    <option value="El Salvador">El Salvador</option>
                                                    <option value="Guatemala">Guatemala</option>
                                                    <option value="Honduras">Honduras</option>
                                                    <option value="México">México</option>
                                                    <option value="Nicaragua">Nicaragua</option>
                                                    <option value="Panamá">Panamá</option>
                                                    <option value="Paraguay">Paraguay</option>
                                                    <option value="Perú">Perú</option>
                                                    <option value="República Dominicana">República Dominicana</option>
                                                    <option value="Uruguay">Uruguay</option>
                                                    <option value="Venezuela">Venezuela</option>
                                                </select>                                        </div>
                                            <div className="col-lg-3">
                                                <label htmlFor="phone" className="form-label">Número de teléfono*</label>
                                                <input required value={phone} name="phone" onChange={e => onChange(e)} type="number" className="form-control" placeholder="+57" id="phone" />
                                            </div>
                                            <div className="col-lg-6">
                                                <label htmlFor="email" className="form-label">Correo corporativo* </label>
                                                <input required value={email} name="email" onChange={e => onChange(e)} type="email" className="form-control" placeholder="Johndoe@ejemplo.com" id="email" />
                                            </div>
                                            <div className="col-lg-6">
                                                <label htmlFor="companyName" className="form-label">Nombre de la empresa</label>
                                                <input value={company} name="company" onChange={e => onChange(e)} type="text" className="form-control" placeholder="Nombre de la empresa" id="company" />
                                            </div>
                                            <div className="col-lg-6">
                                                <label htmlFor="employees" className="form-label">Número de empleados</label>
                                                <select required value={employees} name="employees" onChange={e => onChange(e)} id="employees" className="form-control"   >
                                                    <option value="1-4" selected>1-4</option>
                                                    <option value="5-10">5-10</option>
                                                    <option value="11-50">11-50</option>
                                                    <option value="51-200">51-200</option>
                                                    <option value="más de 200">más de 200</option>

                                                </select>
                                            </div>
                                            <div className="col-lg-12">
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        id="acceptPolicy"
                                                        name="acceptPolicy"
                                                        checked={formData.acceptPolicy}
                                                        onChange={setAgreed} value={agreed}
                                                        required
                                                    />
                                                    <label htmlFor="acceptPolicy" className="mx-2">
                                                        Acepto la{' '}
                                                        <a href={politica} target="_blank" rel="noopener noreferrer">
                                                            política de tratamiento de datos
                                                        </a>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                <button type="submit" className="button-nav">
                                                    ¡Contáctanos!
                                                </button>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        )}</div>
                </div>
            </Modal>
        </>
    )
}
const mapStateToProps = state => ({

})
export default connect(mapStateToProps, {

})(Contacto)