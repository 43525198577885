import { connect } from "react-redux"
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import innovia from "assets/img/logo innovia.png";
import { useState } from 'react';
import Contacto from "components/home/Contacto";
function NavigationBar() {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  return (
    <>

      <div className="container">
        <Navbar expand="lg" collapseOnSelect>
          <Navbar.Brand href="/">
            <img src={innovia} alt="innovia" className="imgNavbar" style={{ width: "200px" }} />
          </Navbar.Brand>

          {/* Solo un Navbar.Toggle */}
          <Navbar.Toggle aria-controls="navbarScroll" />

          {/* Navbar.Collapse contiene las opciones del menú */}
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto">
              <Nav.Link href="/" className="text-black">Inicio</Nav.Link>
              <Nav.Link href="/servicios" className="text-black">Servicios</Nav.Link>
              <Nav.Link href="/comoLoHacemos" className="text-black">¿Cómo trabajamos?</Nav.Link>
              <Nav.Link href="/blog" className="text-black">Blog</Nav.Link>
            </Nav>

            <div>
              <a href="#!" className="button-nav" onClick={handleOpenModal}>
                ¡Contáctanos!
              </a>

              {/* Modal de contacto */}
              <Contacto show={showModal} handleClose={handleCloseModal} />
            </div>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </>
  )
}
const mapStateToProps = state => ({

})
export default connect(mapStateToProps, {

})(NavigationBar)