import { connect } from "react-redux";
import { get_news, get_filtered, get_blog_list_page } from "redux/actions/news/news"
import Footer from "components/navigation/Footer"
import Layout from "hocs/layouts/Layout"
import { useEffect } from "react"
import moment from 'moment'
import { Link } from "react-router-dom"
import NavigationBar from "components/navigation/NavigationBar";
import { useState } from "react";
import SmallSetPagination from "components/pagination/SmallSetPagination"
import CardBlog from "components/blog/CardBlog";
import FeaturedSection from "components/blog/FeaturedSection";
import project5 from "assets/img/project5.jpg";
import project4 from "assets/img/project4.jpg";
import project2 from "assets/img/project2.jpg";
function Blog({




}) {

    const [formData, setFormData] = useState({
        search: '',

    });

    const { search, } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });




    const onSubmit = e => {

        e.preventDefault();
        let formData = new FormData()
        formData.append('search', search)

        let busqueda = formData.get('search')

        get_filtered(busqueda);


    }


    useEffect(() => {
        get_news()


    }, [])

    return (
        <Layout>
            <NavigationBar />
            <section id="blog">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="intro">
        
                                <h1 className="text-center my-3">Nuestro Blog </h1>
                            </div>
                        </div>
                    </div>
                    <div class="row my-4">
                        <div class="col-md-4">
                            <article class="blog-post">
                                <img src={project5} alt="" className="img-fluid"/>
                          
                                    <div class="content">
                                        <small>01 Dec, 2022</small>
                                        <h5>Web Design trends in 2022</h5>
                                        <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a
                                            piece of classical Latin literature from</p>
                                    </div>
                            </article>
                        </div>
                        <div class="col-md-4">
                            <article class="blog-post">
                            <img src={project4} alt=""  className="img-fluid"/>
                        
                                    <div class="content">
                                        <small>01 Dec, 2022</small>
                                        <h5>Web Design trends in 2022</h5>
                                        <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a
                                            piece of classical Latin literature from</p>
                                    </div>
                            </article>
                        </div>
                        <div class="col-md-4">
                            <article class="blog-post">
                            <img src={project2} alt=""  className="img-fluid"/>
                               
                                    <div class="content">
                                        <small>01 Dec, 2022</small>
                                        <h5>Web Design trends in 2022</h5>
                                        <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a
                                            piece of classical Latin literature from</p>
                                    </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>



            <Footer />
        </Layout >
    )
}
const mapStateToProps = state => ({



})

export default connect(mapStateToProps, {


})(Blog)