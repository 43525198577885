import { connect } from "react-redux"
import { motion } from "framer-motion"
import { MegaphoneFill } from 'react-bootstrap-icons';
import { ChatDotsFill } from 'react-bootstrap-icons';
import { CartCheckFill } from 'react-bootstrap-icons';
import { Headset } from 'react-bootstrap-icons';
import { GearFill } from 'react-bootstrap-icons';
import { ClipboardCheck } from 'react-bootstrap-icons';
import { useState } from 'react';
import Contacto from "components/home/Contacto";

function Services() {
    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);


    return (
        <>
            <section id="services" class="text-center">
                <div class="container my-2">
                    <motion.div initial={{ y: -100, opacity: 0 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0, duration: 1 }} viewport={{ once: true }}  >
                        <div class="row">
                            <div class="col-12">
                                <div class="intro px-5">

                                    <h1 className="my-3">Nuestros servicios</h1>

                                    <p className="mx-auto">Optimiza y gestiona, todos los frentes de tu compañía con las herramientas de automatización, soluciones de RPA y chatbots con inteligencia artificial:</p>
                                </div>
                            </div>
                        </div>
                    </motion.div>

                    <div class="row g-4">
                        <div class="col-lg-4 col-md-6">
                            <motion.div initial={{ y: -100, opacity: 0 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.7, duration: 1 }} viewport={{ once: true }}  >

                                <div class="service ">
                                    <MegaphoneFill size={50} color="#8e00cc" />
                                    <h5>Marketing</h5>
                                    <p>Promociona tus productos y servicios por canales directos que te proporcionan mayor efectividad en la conversión.</p>
                                </div>

                            </motion.div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <motion.div initial={{ y: -100, opacity: 0 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.7, duration: 1 }} viewport={{ once: true }}  >

                                <div class="service ">
                                    <ChatDotsFill size={50} color="#8e00cc" />
                                    <h5>Comunicación</h5>
                                    <p>Automatiza la comunicación de tu negocio mediante el envío de notificaciones, recordatorios, alertas y confirmaciones por los canales digitales más efectivos (Whatsapp, Facebook, Instagram, SMS, Mail).
                                    </p>
                                </div>

                            </motion.div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <motion.div initial={{ y: -100, opacity: 0 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.7, duration: 1 }} viewport={{ once: true }}  >

                                <div class="service ">
                                    <CartCheckFill size={50} color="#8e00cc" />
                                    <h5>Ventas</h5>
                                    <p>Adquiere y convierte a través de experiencias conversacionales que te permiten perfilar, calificar y gestionar cada contacto para acelerar el proceso de venta y mejorar el retorno de tu inversión.</p>
                                </div>

                            </motion.div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <motion.div initial={{ y: -100, opacity: 0 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.7, duration: 1 }} viewport={{ once: true }}  >

                                <div class="service ">
                                    <Headset size={50} color="#8e00cc" />
                                    <h5>Postventa</h5>
                                    <p>Habilita canales de comunicación y atención de fácil acceso para resolver preguntas frecuentes, atender casos, derivar a atención personalizada cuando así se requiera y entregar soluciones de manera oportuna. </p>
                                </div>

                            </motion.div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <motion.div initial={{ y: -100, opacity: 0 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.7, duration: 1 }} viewport={{ once: true }}  >

                                <div class="service ">
                                    <GearFill size={50} color="#8e00cc" />
                                    <h5>Operación</h5>
                                    <p>Integra nuestras soluciones con tus sistemas para ofrecer no solo canales de autogestión, sino también automatización de procesos con RPA. Permite a tus clientes realizar transacciones, gestionar productos y optimizar operaciones internas mediante el desarrollo personalizado y la automatización inteligente.</p>
                                </div>

                            </motion.div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <motion.div initial={{ y: -100, opacity: 0 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.7, duration: 1 }} viewport={{ once: true }}  >

                                <div class="service ">
                                    <ClipboardCheck size={50} color="#8e00cc" />
                                    <h5>Procesos internos</h5>
                                    <p>Automatiza procesos internos con asistentes virtuales, desarrollo de softare a la medida y soluciones RPA, diseñados para optimizar la ejecución de tareas y mejorar la eficiencia de tus equipos de trabajo. Simplifica flujos de trabajo y reduce errores a través de la automatización inteligente y personalizada.</p>
                                </div>

                            </motion.div>
                        </div>
                    </div>
                </div>


                <div>
                    <a href="#!" className="button-nav my-4" onClick={handleOpenModal}>
                        ¡Contáctanos!
                    </a>

                    {/* Modal de contacto */}
                    <Contacto show={showModal} handleClose={handleCloseModal} />
                </div>
            </section>
        </>
    )
}
const mapStateToProps = state => ({

})
export default connect(mapStateToProps, {

})(Services)