import { connect } from "react-redux"
import { motion } from "framer-motion"
import robot from "assets/img/robotfinal.png";
import { Tools, ArrowsExpand, Plug, GraphUpArrow } from "react-bootstrap-icons"
function AboutSection() {


  return (
    <>

      <section className="about_section layout_padding img-fluid" style={{ backgroundImage: `url(${robot})` }}>
        <div class="container">
          <div class="heading_container">
            <motion.div initial={{ x: -100, opacity: 0 }} whileInView={{ opacity: 1, x: 0 }} transition={{ delay: 0.1, duration: 1 }} viewport={{ once: true }}  >

              <h2>
                ¿Por qué nos eligen?
              </h2>

            </motion.div>
          </div>
          <div class="layout_padding2-top">
            <div class="row">
              <div class="col-md-4">
                <motion.div initial={{ x: -100, opacity: 0 }} whileInView={{ opacity: 1, x: 0 }} transition={{ delay: 0.1, duration: 1 }} viewport={{ once: true }}  >
                  <div class="box">
                    <div class="head-box">
                      <div class="img-box">
                        <Tools size={30}/>
                      </div>
                      <h6>
                        Sencillez en la creación
                      </h6>
                    </div>
                    <div class="detail-box">
                      <p>
                        Tu equipo puede autogestionar las herramientas, sin la barrera del código.
                      </p>
                    </div>
                  </div>
                </motion.div>
                <motion.div initial={{ x: -100, opacity: 0 }} whileInView={{ opacity: 1, x: 0 }} transition={{ delay: 0.1, duration: 1 }} viewport={{ once: true }}  >

                  <div class="box">
                    <div class="head-box">
                      <div class="img-box">
                        <ArrowsExpand size={30} />
                      </div>
                      <h6>
                        Flexibilidad y escalabilidad
                      </h6>
                    </div>
                    <div class="detail-box">
                      <p>
                        Tu empresa podrá adaptarse a los requisitos y demandas del mercado sin necesidad de reescribir el código.
                      </p>
                    </div>
                  </div>
                </motion.div>

              </div>
              <div class="col-md-4">
                <div class="main-img-box">
                  <img src="images/download-img.png" alt="" />
                </div>
              </div>
              <div class="col-md-4">
                <motion.div initial={{ x: 100, opacity: 0 }} whileInView={{ opacity: 1, x: 0 }} transition={{ delay: 0.1, duration: 1 }} viewport={{ once: true }}  >

                  <div class="box">
                    <div class="head-box ">
                      <div class="img-box">
                        <Plug size={30}/>
                      </div>
                      <h6>
                        Integraciones sin esfuerzo
                      </h6>
                    </div>
                    <div class="detail-box">
                      <p>
                        Podrás lograr sinergía entre plataformas internas y externas en tu empresa.
                      </p>
                    </div>
                  </div>
                </motion.div>
                <motion.div initial={{ x: 100, opacity: 0 }} whileInView={{ opacity: 1, x: 0 }} transition={{ delay: 0.1, duration: 1 }} viewport={{ once: true }}  >

                <div class="box">
                  <div class="head-box">
                    <div class="img-box">
                      <GraphUpArrow size={30}/>
                    </div>
                    <h6>
                      Análisis y optimización
                    </h6>
                  </div>
                  <div class="detail-box">
                    <p>
                      Tu equipo podrá monitorear resultados, consultarlos y encontrar oportunidades de mejora mientras maximizan la eficiencia.
                    </p>
                  </div>
                </div>
                </motion.div>
                <div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>





    </>
  )
}
const mapStateToProps = state => ({

})
export default connect(mapStateToProps, {

})(AboutSection)