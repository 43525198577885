
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Error404 from 'containers/errors/Error404';
import Home from 'containers/pages/Home';
import store from './store';
import { Provider } from 'react-redux';
import NoticiasBlog from 'containers/pages/NoticiasBlog';
import NoticiasDetail from 'containers/pages/NoticiasDetail';
import Blog from 'containers/pages/Blog';
import Nosotros from 'containers/pages/Nosotros';
import NuestrosServicios from 'containers/pages/NuestrosServicios';



function App() {



  return (
    <Provider store={store}>
      <Router>
        <Routes>
          {/*Error Display */}
          <Route path="*" element={<Error404 />} />

          {/*Home Display */}
          <Route path="/" element={<Home />} />
          {/*Home Display */}
          <Route path="/blog" element={<NoticiasBlog />} />
          {/*Detail Display */}
          <Route path="/blog/:slug" element={<NoticiasDetail />} />
          {/*Blog front Display */}
          <Route path="/blogNews" element={<Blog />} />
          <Route path="/ComoLoHacemos" element={<Nosotros />} />
          <Route path="/Servicios" element={<NuestrosServicios />} />



        </Routes>
      </Router>
    </Provider>

  );
}

export default App;
