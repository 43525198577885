

import Layout from "hocs/layouts/Layout"
import Footer from "components/navigation/Footer"
import HeaderSection from "components/home/HeaderSection"
import FeatureSection from "components/home/FeatureSection"
import AboutSection from "components/home/AboutSection"
import ClientSection from "components/home/ClientSection"
import NavigationBar from "components/navigation/NavigationBar";


function Home() {
 


    return (
        <>

            <Layout>
                <NavigationBar></NavigationBar>
                <HeaderSection></HeaderSection>
                <FeatureSection></FeatureSection>
                <AboutSection></AboutSection>
                <ClientSection></ClientSection>
                <Footer></Footer>

            </Layout>
        </>
    )
}
export default Home