import { connect } from "react-redux"
import { GraphUpArrow, PenFill, Hammer, LifePreserver } from 'react-bootstrap-icons';
import { motion } from "framer-motion";
function Benefits() {


    return (
        <>
            <section class="services section-padding" id="section_3">
                <div class="container">
                    <div class="row">
                        <h1 className="text-center">¿Cómo trabajamos?</h1>




                        <div class="col-md-6 my-3">
                            <motion.div initial={{ x: -100, opacity: 0 }} whileInView={{ opacity: 1, x: 0 }} transition={{ delay: 0, duration: 1 }} viewport={{ once: true }}  >
                                <div class="card-container">
                                    <div class="card_como">
                                        <div class="card-icon">
                                            <GraphUpArrow size={50} color="#8e00cc" />
                                        </div>
                                        <div class="card-content">
                                            <div class="card-header">
                                                <h2>Análisis de necesidades</h2>
                                                <div class="card-number">01</div>
                                            </div>
                                            <p>En la consultoría entendemos las necesidades específicas de tu empresa, conversamos sobre los desafíos que enfrentan los equipos y las áreas donde la automatización puede brindar el mayor impacto.</p>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>


                        </div>


                        <div class="col-md-6 my-3">
                            <motion.div initial={{ x: 100, opacity: 0 }} whileInView={{ opacity: 1, x: 0 }} transition={{ delay: 0, duration: 1 }} viewport={{ once: true }}  >
                                <div class="card-container">
                                    <div class="card_como">
                                        <div class="card-icon">
                                            <PenFill size={40} color="#8e00cc" />
                                        </div>
                                        <div class="card-content">
                                            <div class="card-header">
                                                <h2>Diseño de las soluciones</h2>
                                                <div class="card-number">02</div>
                                            </div>
                                            <p>En esta fase, nuestro equipo de expertos diseña soluciones personalizadas utilizando herramientas no-code y chatbots. Estas soluciones se adaptan a los sistemas existentes de la empresa, asegurando una integración fluida.</p>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>


                        </div>
                        <div class="col-md-6 my-3">
                            <motion.div initial={{ x: -100, opacity: 0 }} whileInView={{ opacity: 1, x: 0 }} transition={{ delay: 0, duration: 1 }} viewport={{ once: true }}  >
                                <div class="card-container">
                                    <div class="card_como">
                                        <div class="card-icon">
                                            <Hammer size={40} color="#8e00cc" />
                                        </div>
                                        <div class="card-content">
                                            <div class="card-header">
                                                <h2>Implementación y seguimiento</h2>
                                                <div class="card-number">03</div>
                                            </div>
                                            <p>Iniciamos con la implementación de las herramientas, durante este proceso, brindamos un acompañamiento para asegurar que las soluciones funcionen de manera óptima.</p>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>


                        </div>
                        <div class="col-md-6 my-3">
                            <motion.div initial={{ x: 100, opacity: 0 }} whileInView={{ opacity: 1, x: 0 }} transition={{ delay: 0, duration: 1 }} viewport={{ once: true }}  >
                            <div class="card-container">
                                <div class="card_como">
                                    <div class="card-icon">
                                        <LifePreserver size={40} color="#8e00cc" />
                                    </div>
                                    <div class="card-content">
                                        <div class="card-header">
                                            <h2>Capacitación y soporte</h2>
                                            <div class="card-number">04</div>
                                        </div>
                                        <p>Proporcionamos capacitación detallada al personal de la empresa para garantizar que puedan aprovechar al máximo las herramientas. En Innovia te ofrecemos soporte continuo para el uso de las herramientas.</p>
                                    </div>
                                </div>
                            </div>
                            </motion.div>
   

                        </div>



                    </div>
                </div>
            </section>

        </>
    )
}
const mapStateToProps = state => ({

})
export default connect(mapStateToProps, {

})(Benefits)